var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "contact-us-page"
  }, [_c('v-container', {
    staticClass: "fill-height d-flex justify-center align-center"
  }, [_c('v-card', {
    staticClass: "radius-10 pa-7 pa-md-16 contact-us-main-card",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-img', {
    attrs: {
      "contain": "",
      "src": "/media/svg/ic-chat_45.svg",
      "height": "72"
    }
  }), _c('div', {
    staticClass: "display-1 font-weight-bold text-center mt-6 mb-9 mx-auto"
  }, [_vm._v(" We love to chat ")]), _c('div', [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.send.apply(null, arguments);
      }
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|min:3|max:27',
      expression: "'required|min:3|max:27'"
    }],
    attrs: {
      "label": "Your Name",
      "placeholder": "James Bond",
      "counter": "30",
      "filled": ""
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }, 'v-text-field', _vm.veeValidate('Name', 'Name'), false))], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|email',
      expression: "'required|email'"
    }],
    attrs: {
      "label": "Email Address",
      "placeholder": "james@bond.com",
      "filled": ""
    },
    model: {
      value: _vm.form.email,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  }, 'v-text-field', _vm.veeValidate('Email', 'Email'), false))], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|min:3|max:60',
      expression: "'required|min:3|max:60'"
    }],
    attrs: {
      "label": "Subject",
      "placeholder": "What do you want to discuss about?",
      "counter": "60",
      "filled": ""
    },
    model: {
      value: _vm.form.subject,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "subject", $$v);
      },
      expression: "form.subject"
    }
  }, 'v-text-field', _vm.veeValidate('Subject', 'Subject'), false))], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'max:600|required',
      expression: "'max:600|required'"
    }],
    attrs: {
      "label": "Description",
      "counter": "600",
      "placeholder": "How can we help you?",
      "filled": "",
      "auto-grow": ""
    },
    model: {
      value: _vm.form.message,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "message", $$v);
      },
      expression: "form.message"
    }
  }, 'v-textarea', _vm.veeValidate('Description', 'Description'), false))], 1)], 1), _c('v-btn', {
    staticClass: "primary main wide large mx-auto",
    attrs: {
      "type": "submit",
      "block": "",
      "disabled": _vm.busy || _vm.errors.any() || !_vm.form.name || !_vm.form.email || !_vm.form.subject || !_vm.form.message,
      "loading": _vm.busy
    }
  }, [_vm._v(" send ")])], 1)], 1)], 1)], 1), _c('vue-snackbar')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }